import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input/index.jsx';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal/index.jsx';
import Button from '../../../../components/button/index.jsx';
import { deleteClientWalletReq } from '../../../../api/client/wallet';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';


const ConfirmationDeleteWallet = ({ deleteWalletOpen, setDeleteWalletOpen, userId, walletId, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  
  const deleteHandler = async (id, walletId) => {
    setLoading(true);
    let status = await deleteClientWalletReq(id, walletId);
    setLoading(false);
    setDeleteWalletOpen(false);
    if (status === 'success') {
      //Correct error : Expected '===' and instead saw '=='     eqeqeq
      refreshData();
    }
  };

  const DeleteClientInitialValues = {
    //confirmLastnameUppercase: '',
  };
  const DeleteClientSchema = Yup.object().shape({
    //confirmLastnameUppercase: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={deleteWalletOpen} toggle={setDeleteWalletOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={DeleteClientInitialValues}
        validationSchema={DeleteClientSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          deleteHandler(userId, walletId).then((res) => {
            if (res === 'success') {
              setDeleteWalletOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setDeleteWalletOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Pour confirmer la suppression du coffre
            </div>
            <div className="o-btn-group">
              <Button className="warning" btnType="outline" type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setDeleteWalletOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteWallet;
