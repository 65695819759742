import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';
var qs = require('qs');

export async function clientWalletListReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_wallet_list.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
export async function clientWalletReq(userId, walletId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_wallet_detail.replace('{{userId}}', userId).replace('{{walletId}}', walletId));
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function deleteClientWalletReq(userId, walletId) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.client_wallet_detail.replace('{{userId}}', userId).replace('{{walletId}}', walletId));
    if (res.status === 204) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientWalletTransactionPageReq(userId, walletId, pageNo, pageSize) {
  pageNo = pageNo == null ? 0 : pageNo;
  pageSize = pageSize == null ? 100 : pageSize;
  let queryParams = { params: {
    pageNo : pageNo,
    pageSize : pageSize,
    sortBy : 'valueDate',
  }};
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.client_wallet_transactions.replace('{{userId}}', userId).replace('{{walletId}}', walletId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
